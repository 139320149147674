<template>
    <v-expansion-panel v-on="$listeners">
        <v-expansion-panel-header>
            <div class="font-weight-bold header">Alcohol</div>
            <template v-slot:actions>
                <v-icon left class="icon">
                    mdi-chevron-down
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <text-field-row
                line-number="1"
                title="Pop up title"
                v-model="value.popupTitle"
            />
            <textarea-row
                line-number="2"
                title="Pop up copy"
                v-model="value.popupCopy"
            />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import TextareaRow from "components/create_store/fields/TextareaRow";
import TextFieldRow from "components/create_store/fields/TextFieldRow";

export default {
    name: "AlcoholSection",
    components: {
        TextareaRow,
        TextFieldRow,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        lineNumberProps: {
            type: Object,
            required: true,
        }
    },
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
