<template>
  <iframe class="preview__iframe" :src="iframeUrl" ref="iframe" />
</template>

<script>
export default {
    name: "Preview",
    props: {
        waypointId: {
            type: String,
            required: true,
        },
        form: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        openTab: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    methods: {
        previewBaseUrl(waypointId) {
            return `https://orderandpaystage.com/p2/${waypointId}/`;
        },
        postStylesUpdate(ref, data) {
            ref.contentWindow.postMessage({
                message_id: 'update_styles',
                data,
            }, "*");
        },
        postResourcesUpdate(ref, data) {
            ref.contentWindow.postMessage({
                message_id: 'update_resources',
                data,
            }, "*");
        },
        postUpdates(ref, form) {
            this.postResourcesUpdate(ref, this.getResourcesDataObject(form));
            this.postStylesUpdate(ref, this.getStylesDataObject(form));
        },
        getStylesDataObject(form) {
            return {
                // Spinner
                'spinner-color': form.launchScreen.imageType === 'single' ? form.launchScreen.singleImage.spinner : form.launchScreen.tiled.spinner,

                // Launch screen
                'start-btn-background': form.launchScreen.singleImage.startButtonColor,
                'start-btn-text-color': form.launchScreen.singleImage.startButtonTextColor,
                'intro-link-color': form.launchScreen.singleImage.headerButtonColor,
                'intro-text-color': form.launchScreen.singleImage.headerTextColor,
                'intro-background': form.launchScreen.singleImage.headerBackgroundColor,
                'select-language-color': form.launchScreen.singleImage.languageIcon,

                // Basket
                'edit-icon-color': form.basket.editButtonColor,
                'edit-basket-color': form.basket.editBasketScreenTitleColor,
                'order-more-btn-text-color': form.basket.orderMoreButtonTextColor,
                'order-more-btn-border': form.basket.orderMoreButtonBorderColor,

                // Tip
                'tip-border-color': form.tip.buttonBorder,
                'tip-background': form.tip.buttonsBackgroundColor,
                'tip-text-color': form.tip.textColor,

                // Menu
                'menu-title-color': form.menu.categoryButtonTextColor,
                'menu-item-color': form.menu.itemNamesTextColor,
                'menu-item-popular-color': form.menu.popularItemTextColor,
                'selected-category-background': form.menu.categoryButtonBackgroundColor,
                'selected-category-text-color': form.menu.categoryNamesTextColor,

                // Modal
                'modal-title-color': form.modal.textColor,
                'modal-btn-text-color': form.modal.leftButtonTextColor,
                'modal-btn-border': form.modal.leftButtonBorderColor,
                'modal-background-color': form.modal.backgroundColor,

                // Product
                'product-title': form.product.itemNameTextColor,
                'product-question': form.product.textColorForOptions,
                'product-option-choice-background': form.product.backgroundColorForRadioButtons,
                'product-option-choice-check': form.product.colorForSelectedRadioButtons,

                // Open tab header
                'tab-open-color': form.tab.openTabHeaderColor,

                // Upsell
                'suggestions-check-color': form.upsell.checkColorOnModal,
                'suggestions-quantity-color': form.upsell.quantityButtonColor,
                'suggestions-selected-color': form.upsell.selectedItemsBorderColor,

                // Thank you / checkout
                'checkout-title': form.thankYou.titleTextColor,
                'checkout-survey': form.thankYou.surveyHyperlinkTextColor,

            };
        },
        getResourcesDataObject(form) {
            return {
                home: {
                    start_text: form.launchScreen.singleImage.startButtonText,
                    allergen_link_text: form.launchScreen.singleImage.allergyCopy,
                    // terms_html:,
                    // discount_html: ,
                    // footer_html:
                },
            };
        }
    },
    watch: {
        form: {
            deep: true,
            handler(newVal) {
                this.postUpdates(this.$refs.iframe, newVal);
            }
        }
    },
    computed: {
        iframeUrl() {
            const baseUrl = this.previewBaseUrl(this.waypointId);
            switch (this.type) {
            case 'spinner':
                return `${baseUrl}menu?preview=true&opentab=true`;
            case 'launchScreen':
                return `${baseUrl}?preview=true`;
            case 'menu':
                return `${baseUrl}menu?preview=true&opentab=${this.openTab}`;
            case 'basket':
                return `${baseUrl}cart?preview=true&opentab=${this.openTab}`;
            case 'checkout':
                return `${baseUrl}checkout-complete?preview=true&opentab=${this.openTab}`;
            default:
                return `${baseUrl}preview=true`;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import "preview.scss";
</style>