var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("wizard-stepper", { attrs: { value: "2" } }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("h2", [_vm._v("Design")]),
                  _c(
                    "v-expansion-panels",
                    [
                      _c("configuration-section", {
                        attrs: { isHMSHost: true, isSSP: true },
                        model: {
                          value: _vm.form.configuration,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "configuration", $$v)
                          },
                          expression: "form.configuration",
                        },
                      }),
                      _c("default-colors-section", {
                        model: {
                          value: _vm.form.defaultColors,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "defaultColors", $$v)
                          },
                          expression: "form.defaultColors",
                        },
                      }),
                      _c("launch-screen-section", {
                        on: {
                          onAddTile: function ($event) {
                            return _vm.addSecondaryTile()
                          },
                          onDiscardTile: _vm.removeTile,
                          onReorderTileUpward: _vm.onReorderTileUpward,
                          onReorderTileDownward: _vm.onReorderTileDownward,
                          click: function ($event) {
                            _vm.previewType = "launchScreen"
                          },
                        },
                        model: {
                          value: _vm.form.launchScreen,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "launchScreen", $$v)
                          },
                          expression: "form.launchScreen",
                        },
                      }),
                      _c("modal-section", {
                        model: {
                          value: _vm.form.modal,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "modal", $$v)
                          },
                          expression: "form.modal",
                        },
                      }),
                      _c("table-number-section", {
                        attrs: { "line-number-props": _vm.lineNumberProps },
                        model: {
                          value: _vm.form.tableNumbers,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "tableNumbers", $$v)
                          },
                          expression: "form.tableNumbers",
                        },
                      }),
                      _c("menu-section", {
                        attrs: { "line-number-props": _vm.lineNumberProps },
                        on: {
                          click: function ($event) {
                            _vm.previewType = "menu"
                          },
                        },
                        model: {
                          value: _vm.form.menu,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "menu", $$v)
                          },
                          expression: "form.menu",
                        },
                      }),
                      _c("product-section", {
                        attrs: { "line-number-props": _vm.lineNumberProps },
                        model: {
                          value: _vm.form.product,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "product", $$v)
                          },
                          expression: "form.product",
                        },
                      }),
                      _c("upsell-section", {
                        attrs: { "line-number-props": _vm.lineNumberProps },
                        model: {
                          value: _vm.form.upsell,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "upsell", $$v)
                          },
                          expression: "form.upsell",
                        },
                      }),
                      _c("basket-section", {
                        attrs: { "line-number-props": _vm.lineNumberProps },
                        on: {
                          click: function ($event) {
                            _vm.previewType = "basket"
                          },
                        },
                        model: {
                          value: _vm.form.basket,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "basket", $$v)
                          },
                          expression: "form.basket",
                        },
                      }),
                      _c("alcohol-section", {
                        attrs: { "line-number-props": _vm.lineNumberProps },
                        model: {
                          value: _vm.form.alcohol,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "alcohol", $$v)
                          },
                          expression: "form.alcohol",
                        },
                      }),
                      _c("tab-section", {
                        attrs: { "line-number-props": _vm.lineNumberProps },
                        model: {
                          value: _vm.form.tab,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "tab", $$v)
                          },
                          expression: "form.tab",
                        },
                      }),
                      _c("tip-section", {
                        attrs: { "line-number-props": _vm.lineNumberProps },
                        on: {
                          click: function ($event) {
                            _vm.previewType = "basket"
                          },
                        },
                        model: {
                          value: _vm.form.tip,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "tip", $$v)
                          },
                          expression: "form.tip",
                        },
                      }),
                      _c("thank-you-section", {
                        attrs: { "line-number-props": _vm.lineNumberProps },
                        on: {
                          click: function ($event) {
                            _vm.previewType = "checkout"
                          },
                        },
                        model: {
                          value: _vm.form.thankYou,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "thankYou", $$v)
                          },
                          expression: "form.thankYou",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-col", { attrs: { md: "4" } }, [
                _c(
                  "div",
                  { staticStyle: { position: "fixed" } },
                  [
                    _c("h4", { staticClass: "text-center" }, [
                      _vm._v("Menu preview"),
                    ]),
                    _c("preview", {
                      attrs: {
                        type: _vm.previewType,
                        form: _vm.form,
                        "open-tab": _vm.form.tab.status === "open",
                        "waypoint-id": "10000",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }