<template>
    <div>
        <wizard-stepper value="2" />
        <v-container fluid>
            <v-row>
                <v-col md="8">
                    <h2>Design</h2>
                    <v-expansion-panels>
                        <configuration-section
                            v-model="form.configuration"
                            :isHMSHost="true"
                            :isSSP="true"
                        />
                        <default-colors-section
                            v-model="form.defaultColors"
                        />
                        <launch-screen-section
                            v-model="form.launchScreen"
                            @onAddTile="addSecondaryTile()"
                            @onDiscardTile="removeTile"
                            @onReorderTileUpward="onReorderTileUpward"
                            @onReorderTileDownward="onReorderTileDownward"
                            @click="previewType = 'launchScreen'"
                        />
                        <modal-section
                            v-model="form.modal"
                        />
                        <table-number-section
                            v-model="form.tableNumbers"
                            :line-number-props="lineNumberProps"
                        />
                        <menu-section
                            v-model="form.menu"
                            :line-number-props="lineNumberProps"
                            @click="previewType = 'menu'"
                        />
                        <product-section
                            v-model="form.product"
                            :line-number-props="lineNumberProps"
                        />
                        <upsell-section
                            v-model="form.upsell"
                            :line-number-props="lineNumberProps"
                        />
                        <basket-section
                            v-model="form.basket"
                            :line-number-props="lineNumberProps"
                            @click="previewType = 'basket'"
                        />
                        <alcohol-section
                            v-model="form.alcohol"
                            :line-number-props="lineNumberProps"
                        />
                        <tab-section
                            v-model="form.tab"
                            :line-number-props="lineNumberProps"
                        />
                        <tip-section
                            v-model="form.tip"
                            :line-number-props="lineNumberProps"
                            @click="previewType = 'basket'"
                        />
                        <thank-you-section
                            v-model="form.thankYou"
                            :line-number-props="lineNumberProps"
                            @click="previewType = 'checkout'"
                        />
                    </v-expansion-panels>
                </v-col>
                <v-col md="4">
                    <div style="position: fixed;">
                        <h4 class="text-center">Menu preview</h4>
                        <preview
                            :type="previewType"
                            :form="form"
                            :open-tab="form.tab.status === 'open'"
                            waypoint-id="10000"
                        />
                    </div>
                  </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import WizardStepper from '../wizard_stepper';
import ConfigurationSection from './configuration_section';
import LaunchScreenSection from './launch_screen_section';
import TableNumberSection from './table_number_section';
import AlcoholSection from './alcohol_section';
import TabSection from './tab_section';
import TipSection from './tip_section';
import ThankYouSection from './thank_you_section';
import ModalSection from './modal_section';
import MenuSection from './menu_section';
import ProductSection from './product_section';
import UpsellSection from './upsell_section';
import BasketSection from './basket_section';
import Preview from 'components/create_store/preview/index';
import DefaultColorsSection from "pages/create_store/wizard/design/default_colors_section";
import axios from "axios";

const defaultColors = {
    primary: '#648b4e',
    secondary: '#f2c400',
    black: '#000',
    white: '#ffffff',
};

export default {
    name: "design_step",
    components: {
        DefaultColorsSection,
        WizardStepper,
        ConfigurationSection,
        LaunchScreenSection,
        TableNumberSection,
        AlcoholSection,
        TabSection,
        TipSection,
        ThankYouSection,
        ModalSection,
        MenuSection,
        ProductSection,
        UpsellSection,
        BasketSection,
        Preview,
    },
    data() {
        return {
            http: axios.create(),
            isHMSHost: true,
            isSSP: true,
            previewType: 'spinner',
            form: {
                configuration: {
                    orderAtTable: false,
                    virtualKiosk: false,
                    tab: null,
                    openTabThreshold: null,
                    openTabAutoCloseTime: null,
                    queryPOSForAnyCheck: false,
                    forceEmailAndTextCollectionBeforePayment: false,
                    requestedUrl: null,
                    googleAnalytics: null,
                    siteLogo: null,
                    receiptLogo: null,
                    allergyText: null,
                    displayUSCalorie: false,
                    tablesEnabled: false,
                    tippingEnabled: false,
                    defaultTips: [null, null, null],
                    tippingAtCloseOnly: false,
                    autoAddTipOnClose: false,
                    autoCloseTip: null,
                    employeeDiscount: false,
                    displayDiscountAdvertisement: false,
                    existingTabAdvertisement: false,
                    autoReceipt: false,
                    textReceipt: false,
                    staticUpsellAndAddOn: false,
                    isProductionStore: false,
                    emailReceiptLogo: null,
                    surveyLinkOrStarRating: null,
                    surveyUrl: null,
                    privacyUrl: null,
                    termsUrl: null,
                },
                defaultColors: {
                    primary: defaultColors.primary,
                    secondary: defaultColors.secondary,
                    black: defaultColors.black,
                    white: defaultColors.white,
                },
                launchScreen: {
                    imageType: null,
                    singleImage: {
                        backgroundColor: defaultColors.white,
                        spinner: defaultColors.white,
                        languageIcon: defaultColors.primary,
                        textColor: defaultColors.black,
                        logo: null,
                        featuresItems: null,
                        startButtonColor: defaultColors.primary,
                        startButtonText: 'Start Your Order',
                        startButtonTextColor: defaultColors.black,
                        headerBackgroundColor: defaultColors.white,
                        headerTextColor: defaultColors.black,
                        headerButtonColor: defaultColors.primary,
                        allergyCopy: 'Please click here for menu and allergen information',
                        allergyModalTitle: null,
                        allergyModalCopy: null,
                        linkColor: defaultColors.primary,
                        caloricInformation: null,
                    },
                    tiled: {
                        backgroundColor: defaultColors.white,
                        spinner: defaultColors.white,
                        languageIcon: defaultColors.primary,
                        logo: null,
                        order: null,
                        labelColor: defaultColors.white,
                        primaryTile: {
                            type: 'mainMenu',
                            label: null,
                            labelColor: defaultColors.black,
                            icon: {
                                type: 'default',
                                file: null,
                                color: defaultColors.white,
                            },
                            tileColor: defaultColors.black,
                            tileImage: {
                                type: 'default',
                                file: null,
                            },
                            overlayColor: {
                                type: 'noColor',
                                color: defaultColors.white,
                            },
                        },
                        secondaryTiles: [
                        ]
                    }
                },
                tableNumbers: {
                    popupCopy: null,
                    numbers: [[1,2]],
                    sections: false,
                    sectionsSet: false,
                },
                alcohol: {
                    popupTitle: null,
                    popupCopy: null,
                },
                tab: {
                    status: null,
                    threshold: null,
                    autotabCloseTime: null,
                    openTabHeaderColor: defaultColors.white,
                },
                tip: {
                    buttonBorder: defaultColors.primary,
                    buttonsBackgroundColor: defaultColors.primary,
                    textColor: defaultColors.white,
                },
                thankYou: {
                    titleTextColor: defaultColors.black,
                    surveyHyperlinkTextColor: defaultColors.black,
                },
                modal: {
                    backgroundColor: defaultColors.white,
                    textColor: defaultColors.black,
                    leftButtonTextColor: defaultColors.primary,
                    leftButtonBorderColor: defaultColors.primary,
                },
                menu: {
                    categoryNamesTextColor: defaultColors.black,
                    categoryButtonBackgroundColor: defaultColors.primary,
                    categoryButtonTextColor: defaultColors.white,
                    itemNamesTextColor: defaultColors.black,
                    popularItemTextColor: defaultColors.secondary,
                },
                product: {
                    itemNameTextColor: defaultColors.black,
                    textColorForOptions: defaultColors.black,
                    backgroundColorForRadioButtons: defaultColors.primary,
                    colorForSelectedRadioButtons: defaultColors.white,
                },
                upsell: {
                    checkColorOnModal: defaultColors.primary,
                    quantityButtonColor: defaultColors.primary,
                    selectedItemsBorderColor: defaultColors.primary,
                },
                basket: {
                    editButtonColor: defaultColors.primary,
                    editBasketScreenTitleColor: defaultColors.primary,
                    orderMoreButtonTextColor: defaultColors.primary,
                    orderMoreButtonBorderColor: defaultColors.primary,
                }
            },
            lineNumberProps: {
                md: '1',
                sm: '2',
                lg: '1',
            },
        };
    },
    mounted() {
        this.fetchMetadata();
    },
    methods: {
        addSecondaryTile() {
            this.form.launchScreen.tiled.secondaryTiles = [
                ...this.form.launchScreen.tiled.secondaryTiles,
                {
                    type: 'tab',
                    label: null,
                    labelColor: this.form.defaultColors.black,
                    icon: {
                        type: 'default',
                        file: null,
                        color: this.form.defaultColors.white,
                    },
                    tileColor: this.form.defaultColors.black,
                    tileImage: {
                        type: 'default',
                        file: null,
                    },
                    overlayColor: {
                        type: 'noColor',
                        color: this.form.defaultColors.white,
                    },
                    pageLink: '',

                    survey: {
                        iconType: 'stars',
                        questions: ['How was the service?'],
                    },
                    nutritionalInformation: {
                        popupTitle: null,
                        popupCopy: null,
                    },
                    about: {
                        popupTitle: null,
                        popupCopy: null,
                    },
                    caloricInformation: null,
                }
            ];
        },
        removeTile(index) {
            this.form.launchScreen.tiled.secondaryTiles.splice(index, 1);
        },
        onReorderTileUpward(index) {
            if (index === 0) {
                return;
            }

            const tiles = this.form.launchScreen.tiled.secondaryTiles;
            [tiles[index - 1], tiles[index]] = [tiles[index], tiles[index - 1]];
            this.form.launchScreen.tiled.secondaryTiles = [...tiles];
        },
        onReorderTileDownward(index) {
            const tiles = this.form.launchScreen.tiled.secondaryTiles;
            if (index === tiles.length - 1) {
                return;
            }

            [tiles[index], tiles[index + 1]] = [tiles[index + 1], tiles[index]];
            this.form.launchScreen.tiled.secondaryTiles = [...tiles];
        },
        fetchMetadata() {
            this.http
                .get("/api/styles/metadata")
                .then(x => {
                    console.error(x);
                });
        },
    },
    watch: {
        'form.defaultColors.primary': {
            handler(newVal) {
                this.form.launchScreen.singleImage.languageIcon = newVal;
                this.form.launchScreen.singleImage.startButtonColor = newVal;
                this.form.launchScreen.singleImage.headerButtonColor = newVal;
                this.form.launchScreen.singleImage.linkColor = newVal;

                this.form.launchScreen.tiled.languageIcon = newVal;

                this.form.tip.buttonBorder = newVal;
                this.form.tip.buttonsBackgroundColor = newVal;

                this.form.modal.leftButtonTextColor = newVal;
                this.form.modal.leftButtonBorderColor = newVal;

                this.form.menu.categoryButtonBackgroundColor = newVal;

                this.form.product.backgroundColorForRadioButtons = newVal;

                this.form.upsell.checkColorOnModal = newVal;
                this.form.upsell.quantityButtonColor = newVal;
                this.form.upsell.selectedItemsBorderColor = newVal;

                this.form.basket.editButtonColor = newVal;
                this.form.basket.editBasketScreenTitleColor = newVal;
                this.form.basket.orderMoreButtonTextColor = newVal;
                this.form.basket.orderMoreButtonBorderColor = newVal;
            },
        },
        'form.defaultColors.secondary': {
            handler(newVal) {
                this.form.menu.popularItemTextColor = newVal;
            },
        },
        'form.defaultColors.black': {
            handler(newVal) {
                this.form.launchScreen.singleImage.textColor = newVal;
                this.form.launchScreen.singleImage.startButtonTextColor = newVal;
                this.form.launchScreen.singleImage.headerTextColor = newVal;

                this.form.launchScreen.tiled.primaryTile.labelColor = newVal;
                this.form.launchScreen.tiled.primaryTile.tileColor = newVal;

                this.form.thankYou.titleTextColor = newVal;
                this.form.thankYou.surveyHyperlinkTextColor = newVal;

                this.form.modal.textColor = newVal;

                this.form.menu.categoryNamesTextColor = newVal;
                this.form.menu.itemNamesTextColor = newVal;

                this.form.product.itemNameTextColor = newVal;
                this.form.product.textColorForOptions = newVal;
            },
        },
        'form.defaultColors.white': {
            handler(newVal) {
                this.form.launchScreen.singleImage.backgroundColor = newVal;
                this.form.launchScreen.singleImage.spinner = newVal;
                this.form.launchScreen.singleImage.headerBackgroundColor = newVal;

                this.form.launchScreen.tiled.backgroundColor = newVal;
                this.form.launchScreen.tiled.spinner = newVal;
                this.form.launchScreen.tiled.labelColor = newVal;
                this.form.launchScreen.tiled.primaryTile.icon.color = newVal;
                this.form.launchScreen.tiled.primaryTile.tileColor = newVal;
                this.form.launchScreen.tiled.primaryTile.overlayColor.color = newVal;

                this.form.tab.openTabHeaderColor = newVal;
                this.form.tab.textColor = newVal;

                this.form.modal.backgroundColor = newVal;

                this.form.menu.categoryButtonTextColor = newVal;

                this.form.product.colorForSelectedRadioButtons = newVal;
            },
        }
    },
}
</script>

<style scoped lang="scss">
    @import "/src/pages/create_store/wizard/section";
</style>
