<template>
    <v-expansion-panel v-on="$listeners">
        <v-expansion-panel-header>
            <div class="font-weight-bold header">Upsell</div>
            <template v-slot:actions>
                <v-icon left class="icon">
                    mdi-chevron-down
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <color-input-row
                line-number="1"
                title="Check color on modal"
                v-model="value.checkColorOnModal"
            />
            <v-divider />
            <color-input-row
                line-number="2"
                title="Quantity button color on modal"
                v-model="value.quantityButtonColor"
            />
            <v-divider />
            <color-input-row
                line-number="3"
                title="Selected items border color"
                v-model="value.selectedItemsBorderColor"
            />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import LineNumber from "components/LineNumber";
import TextTooltip from "components/text_tooltip";
import ColorInputRow from "components/create_store/fields/ColorInputRow";

export default {
    name: "UpsellSection",
    components: {
        LineNumber,
        TextTooltip,
        ColorInputRow,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        lineNumberProps: {
            type: Object,
            required: true,
        }
    },
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
