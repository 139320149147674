var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    _vm._g({}, _vm.$listeners),
    [
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "font-weight-bold header" }, [
            _vm._v("Product"),
          ]),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        [
          _c("color-input-row", {
            attrs: { title: "Item name text color", "line-number": "1" },
            model: {
              value: _vm.value.itemNameTextColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "itemNameTextColor", $$v)
              },
              expression: "value.itemNameTextColor",
            },
          }),
          _c("v-divider"),
          _c("color-input-row", {
            attrs: {
              title: "Text color for option/choices",
              "line-number": "2",
            },
            model: {
              value: _vm.value.textColorForOptions,
              callback: function ($$v) {
                _vm.$set(_vm.value, "textColorForOptions", $$v)
              },
              expression: "value.textColorForOptions",
            },
          }),
          _c("v-divider"),
          _c("color-input-row", {
            attrs: {
              title:
                "Background color for radio/check buttons for options/choices",
              "line-number": "3",
            },
            model: {
              value: _vm.value.backgroundColorForRadioButtons,
              callback: function ($$v) {
                _vm.$set(_vm.value, "backgroundColorForRadioButtons", $$v)
              },
              expression: "value.backgroundColorForRadioButtons",
            },
          }),
          _c("v-divider"),
          _c("color-input-row", {
            attrs: {
              title:
                "Color for selected radio/check buttons for options/choices",
              "line-number": "4",
            },
            model: {
              value: _vm.value.colorForSelectedRadioButtons,
              callback: function ($$v) {
                _vm.$set(_vm.value, "colorForSelectedRadioButtons", $$v)
              },
              expression: "value.colorForSelectedRadioButtons",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }