<template>
    <v-expansion-panel v-on="$listeners">
        <v-expansion-panel-header>
            <div class="font-weight-bold header">Tab</div>
            <template v-slot:actions>
                <v-icon left class="icon">
                    mdi-chevron-down
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <radio-group-row
                :items="[{label: 'Open', value: 'open'}, {label: 'Closed', value: 'closed'}]"
                title="Open or closed?"
                line-number="1"
                v-model="value.status"
            />
            <v-divider />
            <template v-if="value.status === 'closed'">
                <text-field-row
                    line-number="2"
                    title="Tab threshold"
                    v-model="value.threshold"
                />
                <v-divider />
                <text-field-row
                    line-number="3"
                    title="Autotab close time"
                    v-model="value.autotabCloseTime"
                />
            </template>

            <color-input-row
                v-if="value.status === 'open'"
                line-number="2"
                title="Open tab header color"
                v-model="value.openTabHeaderColor"
            />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import ColorInputRow from "components/create_store/fields/ColorInputRow";
import RadioGroupRow from "components/create_store/fields/RadioGroupRow";
import TextFieldRow from "components/create_store/fields/TextFieldRow";

export default {
    name: "TabSection",
    components: {
        ColorInputRow,
        RadioGroupRow,
        TextFieldRow,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        lineNumberProps: {
            type: Object,
            required: true,
        }
    },
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
