<template>
    <v-expansion-panel v-on="$listeners">
        <v-expansion-panel-header>
            <div class="font-weight-bold header">Menu</div>
            <template v-slot:actions>
                <v-icon left class="icon">
                    mdi-chevron-down
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <color-input-row
                title="Category names text color"
                line-number="1"
                v-model="value.categoryNamesTextColor"
            />
            <v-divider />
            <color-input-row
                title="Category button background color"
                line-number="2"
                v-model="value.categoryButtonBackgroundColor"
            />
            <v-divider />
            <color-input-row
                title="Category button text color"
                line-number="3"
                v-model="value.categoryButtonTextColor"
            />
            <v-divider />
            <color-input-row
                title="Item names text color"
                line-number="4"
                v-model="value.itemNamesTextColor"
            />
            <v-divider />
            <color-input-row
                title="Popular item text color"
                line-number="5"
                v-model="value.popularItemTextColor"
            />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import ColorInputRow from "components/create_store/fields/ColorInputRow";

export default {
    name: "MenuSection",
    components: {
        ColorInputRow,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        lineNumberProps: {
            type: Object,
            required: true,
        }
    },
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
