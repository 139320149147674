var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    _vm._g({}, _vm.$listeners),
    [
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "font-weight-bold header" }, [
            _vm._v("Alcohol"),
          ]),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        [
          _c("text-field-row", {
            attrs: { "line-number": "1", title: "Pop up title" },
            model: {
              value: _vm.value.popupTitle,
              callback: function ($$v) {
                _vm.$set(_vm.value, "popupTitle", $$v)
              },
              expression: "value.popupTitle",
            },
          }),
          _c("textarea-row", {
            attrs: { "line-number": "2", title: "Pop up copy" },
            model: {
              value: _vm.value.popupCopy,
              callback: function ($$v) {
                _vm.$set(_vm.value, "popupCopy", $$v)
              },
              expression: "value.popupCopy",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }