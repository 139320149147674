var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    _vm._g({}, _vm.$listeners),
    [
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("div", { staticClass: "font-weight-bold header" }, [_vm._v("Tab")])]
      ),
      _c(
        "v-expansion-panel-content",
        [
          _c("radio-group-row", {
            attrs: {
              items: [
                { label: "Open", value: "open" },
                { label: "Closed", value: "closed" },
              ],
              title: "Open or closed?",
              "line-number": "1",
            },
            model: {
              value: _vm.value.status,
              callback: function ($$v) {
                _vm.$set(_vm.value, "status", $$v)
              },
              expression: "value.status",
            },
          }),
          _c("v-divider"),
          _vm.value.status === "closed"
            ? [
                _c("text-field-row", {
                  attrs: { "line-number": "2", title: "Tab threshold" },
                  model: {
                    value: _vm.value.threshold,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "threshold", $$v)
                    },
                    expression: "value.threshold",
                  },
                }),
                _c("v-divider"),
                _c("text-field-row", {
                  attrs: { "line-number": "3", title: "Autotab close time" },
                  model: {
                    value: _vm.value.autotabCloseTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "autotabCloseTime", $$v)
                    },
                    expression: "value.autotabCloseTime",
                  },
                }),
              ]
            : _vm._e(),
          _vm.value.status === "open"
            ? _c("color-input-row", {
                attrs: { "line-number": "2", title: "Open tab header color" },
                model: {
                  value: _vm.value.openTabHeaderColor,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "openTabHeaderColor", $$v)
                  },
                  expression: "value.openTabHeaderColor",
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }