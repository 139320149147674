var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    _vm._g({}, _vm.$listeners),
    [
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("div", { staticClass: "font-weight-bold header" }, [_vm._v("Tip")])]
      ),
      _c(
        "v-expansion-panel-content",
        [
          _c("color-input-row", {
            attrs: { "line-number": "1", title: "Tip button border" },
            model: {
              value: _vm.value.buttonBorder,
              callback: function ($$v) {
                _vm.$set(_vm.value, "buttonBorder", $$v)
              },
              expression: "value.buttonBorder",
            },
          }),
          _c("v-divider"),
          _c("color-input-row", {
            attrs: {
              "line-number": "2",
              title: "Tip buttons background color",
            },
            model: {
              value: _vm.value.buttonsBackgroundColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "buttonsBackgroundColor", $$v)
              },
              expression: "value.buttonsBackgroundColor",
            },
          }),
          _c("v-divider"),
          _c("color-input-row", {
            attrs: { "line-number": "3", title: "Tip text color" },
            model: {
              value: _vm.value.textColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "textColor", $$v)
              },
              expression: "value.textColor",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }