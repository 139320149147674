var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    ref: "iframe",
    staticClass: "preview__iframe",
    attrs: { src: _vm.iframeUrl },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }