var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    _vm._g({}, _vm.$listeners),
    [
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "font-weight-bold header" }, [
            _vm._v("Basket"),
          ]),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        [
          _c("color-input-row", {
            attrs: { "line-number": "1", title: "Edit button color" },
            model: {
              value: _vm.value.editButtonColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "editButtonColor", $$v)
              },
              expression: "value.editButtonColor",
            },
          }),
          _c("v-divider"),
          _c("color-input-row", {
            attrs: {
              "line-number": "2",
              title: "Edit basket screen title color",
            },
            model: {
              value: _vm.value.editBasketScreenTitleColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "editBasketScreenTitleColor", $$v)
              },
              expression: "value.editBasketScreenTitleColor",
            },
          }),
          _c("v-divider"),
          _c("color-input-row", {
            attrs: {
              "line-number": "3",
              title: "Order More button text color",
            },
            model: {
              value: _vm.value.orderMoreButtonTextColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "orderMoreButtonTextColor", $$v)
              },
              expression: "value.orderMoreButtonTextColor",
            },
          }),
          _c("v-divider"),
          _c("color-input-row", {
            attrs: {
              "line-number": "4",
              title: "Oder More button border color",
            },
            model: {
              value: _vm.value.orderMoreButtonBorderColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "orderMoreButtonBorderColor", $$v)
              },
              expression: "value.orderMoreButtonBorderColor",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }